import * as React from "react"

import ProjectLayout from "../components/layout"
import Seo from "../components/seo"

export default function GridTest() {
  return (
    <ProjectLayout>
      <Seo title="GridTest " />
      <h1>This is a test</h1>
    </ProjectLayout>
  )
}
